import './userform.css'
import {useForm} from 'react-hook-form'
import MultiCreatableSelect from '../multicreatableselect/MultiCreatableSelect';
import SingleCreatableSelect from '../singlecreatableselect/SingleCreatableSelect';
import SingleSelect from '../singleselect/SingleSelect';
import { cuisineOptions } from '../../options';
import { cookingStyleOptions } from '../../options';
import RangeSlider from '../rangeslider/RangeSlider';
import {useState} from 'react'
import axios from 'axios';


function UserForm({onData, generatingstatus}) {
    const { control, register, handleSubmit, reset, formState} = useForm();
    const [gptRes, setGptRes] = useState()
    const [isFetching , setIsFetching] = useState(false)

    const onSubmit =  async (data) => {
        setIsFetching(true)
        generatingstatus(true)
            const requestBody = {
                ingredients: data.ingredients.map((i) => i.value).join(',') || "I am open to all the ingredients",
                cuisine: data.cuisine.value || "No Specific Cuisine", 
                cookingStyle: data.cookingStyle.value || "No Specific Cooking Style",
                maxCalories: data.maxCalorie || "2500",
                minCalories:"0",
                maxProtein:data.maxProtein || "200", 
                minProtein: "0", 
                dietaryRestrictions: data.dietaryRestrictions.value || "I have no restrictions"
            }
            await axios.post('https://cofit-backend.herokuapp.com/api/gpt', requestBody).then((response) => {
                setGptRes(response.data.bot.content)
                onData(response.data.bot.content)
               generatingstatus(false)
                setIsFetching(false)
            }).catch((err) => {
                alert("Server Error: Unable to Load Recipe")
                setIsFetching(false)
                generatingstatus(false)
            })
  }


  const handleReset = () =>{
    reset();
  }

  const restrictionOptions = [
    { value: 'No Restrictions', label: 'No Restrictions' },
    // { value: 'eggs', label: 'Eggs' },
    // { value: 'spinach', label: 'Spinach' },
  ];
  
  const options = []
  
  return (
    <div className='uf-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control form-ingredients form-row">
                <label className='form-label'>Ingredients*</label>
                <MultiCreatableSelect name="ingredients" control={control} options={options} defaultValue={[]} placeholder={"Add multiple ingredients"} />
                {/* {formState.errors.ingredients ? <span>This Field Is Required</span> : <></>} */}
            </div>
            <div className="form-preferences form-row">
                <div className="form-control form-split-left">
                    <label className='form-label'>Cuisine*</label>
                    {/* <input type="text" name="cuisine" {...register("cuisine")}/> */}
                    <SingleSelect name="cuisine" control={control} options={cuisineOptions} {...register("cuisine")}/>
                </div>
                <div className="form-control form-split-right">
                    <label className='form-label'>Cooking Style*</label>
                    {/* <input type="text" name="cookingStyle" {...register("cookingStyle")}/> */}
                    <SingleSelect name="cookingStyle" control={control} options={cookingStyleOptions}  {...register("cookingStyle")}/>
                </div>
            </div>
            <div className="form-control form-restrictions form-row">
                <label className='form-label'>Dietary Restriction*</label>
                <SingleCreatableSelect name="dietaryRestrictions" control={control} options={restrictionOptions} defaultValue="No Restrictions" placeholder={"Short description of any restrictions such as vegan,keto, etc."}/>
            </div>
            <div className="form-macros">
                <div className="form-control form-cal">
                    <label className='form-label'>Calories*</label>
                    <RangeSlider control={control} name="maxCalorie" min="0" max="2500" auto={50} unit="cals"/>
                </div>
                <div className="form-control form-protein">
                    <label className='form-label'>Protein*</label>
                    <RangeSlider control={control} name="maxProtein" min="0" max="200" auto={20} unit ="gm"/>
                </div>
            </div>

           
            <div className="control-btn form-row">
            <button className="generate-button" type="submit" disabled={isFetching || formState.isSubmitting}>{isFetching ? "Generating..." : "Generate"}</button>
            <button className="reset-button" onClick={handleReset}>Reset</button>
            </div>
           
        </form>
    </div>
  )
}

export default UserForm