import './rangeslider.css'
import { useController } from 'react-hook-form';

function RangeSlider({ name, control, auto, min, max, unit}) {
  const { field } = useController({
    name,
    control,
    defaultValue: auto
  });

  return (
    <div className='rangeslider-container'>
      <input className="slider" type="range" min={min} max={max} {...field} required/>
      <p className='slider-value'>{field.value + " " + unit }</p>
    </div>
  );
}

export default RangeSlider;
