import React from 'react';
import { useController } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

const MultiCreatableSelect = ({ name, control, options, defaultValue, placeholder}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
  });

  const handleChange = (value) => {
    field.onChange(value);
  };

  const customCreateLabel = inputValue => `Add "${inputValue}"`
  return (
    <CreatableSelect
      name={name}
      options={options}
      value={field.value}
      onChange={handleChange}
      isMulti // Enable multiple selections
      placeholder={placeholder}
      required
      formatCreateLabel={customCreateLabel}
    />
  );
};

export default MultiCreatableSelect;
