import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/home/Home";
import Feed from "./pages/feed/Feed";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navabar/Navbar";

function App() {
  return (
    <div className="App">
      <div className="app-navbar">
        <Navbar />
      </div>
      <div className="app-pages">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/feed" element={<Feed />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
