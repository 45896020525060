import './navbar.css'

function Navbar() {
  return (
    <div className='navbar-container'>
            <div className="nb-left nb-margin">
                cofit
            </div>
            <div className="nb-right nb-margin">
                
            </div>
    </div>
  )
}

export default Navbar