import React from 'react';
import { useController } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

const SingleCreatableSelect = ({ name, control, options, defaultValue, placeholder}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
  });

  const handleChange = (value) => {
    field.onChange(value);
  };

  const customCreateLabel = inputValue => `Add "${inputValue}"`


  return (
    <CreatableSelect
      name={name}
      options={options}
      value={field.value}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable
      required
      formatCreateLabel={customCreateLabel}
    />
  );
};

export default SingleCreatableSelect;
