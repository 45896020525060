import React from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';

const SingleSelect = ({ name, control, options, defaultValue, placeholder}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
  });

  const handleChange = (value) => {
    field.onChange(value);
  };

  return (
    <Select
      name={name}
      options={options}
      value={field.value}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable
      required
    />
  );
};

export default SingleSelect;
