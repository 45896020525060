export const cuisineOptions = [
  { value: "Italian", label: "Italian" },
  { value: "Mexican", label: "Mexican" },
  { value: "Indian", label: "Indian" },
  { value: "Chinese", label: "Chinese" },
  { value: "No Preference", label: "No Preference" },
];

export const cookingStyleOptions = [
  { value: "Grill", label: "Grill" },
  { value: "Saute", label: "Saute" },
  { value: "Air Fried", label: "Air Fried" },
  { value: "Bake", label: "Bake" },
  { value: "No Preference", label: "No Preference" },
];
