import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import animationData from '../../avocado.json'

function AvocadoAnimation() {
  const containerRef = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <div className ="animation-container" ref={containerRef}></div>
  );
}

export default AvocadoAnimation;
