import './recipehome.css'
import {BiTimeFive } from 'react-icons/bi';
import {FiBarChart} from 'react-icons/fi';
import {VscPieChart} from 'react-icons/vsc';







function RecipeHome({recipeName, ingredients, timetoprepare, calories, protein, instructions}) {
  return (
    <div className='rh-container'>
        <div className="rh-recipename">
              {recipeName}
          </div>
        <div className="rh-top">
            <div className="rh-left">
                  <div className="rh-title">
                    Ingredients
                  </div>
                  <div className="rh-ingredients">
                   
                    {
                      
                      ingredients.map((i) => {
                        return <div className='rh-ingredient'>
                         <div className='ing-name'>
                          {i.ingredient}
                        </div>
                        <div className='ing-quant'>
                          {i.quantity}
                        </div>
                        </div>
                
                      })
                     
                    } 
                    
                  
                  </div>

            </div>
            <div className="rh-right">
                  {/* <div className="rh-title">
                    Macros
                  </div> */}
                  <div className="rh-macros">
                    <div className="rh-item">
                      <BiTimeFive className="rh-icon"/>
                      <div className="rh-value">{timetoprepare}</div>
                    </div>
                    <div className="rh-item">
                      <FiBarChart className="rh-icon"/>
                      <div className="rh-value">{calories}</div>
                    </div>
                    <div className="rh-item">
                      <VscPieChart className="rh-icon"/>
                      <div className="rh-value">{protein}</div>
                    </div>
                  </div>
                  
            </div>
        </div>
        <div className="rh-bottom">
          <div className="rh-title">
                   Instructions
          </div>
          <div className="rh-insts">
              {
                instructions.map((i) => {return (
                <div className='rh-inst'>
                  {
                    i
                  }
                </div>)})
              }
          </div>
        </div>
    </div>
  )
}

export default RecipeHome