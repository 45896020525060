import UserForm from '../../components/userform/UserForm';
import './home.css'
import { AiOutlineForm } from 'react-icons/ai';
import { useState } from 'react';
import RecipeHome from '../../components/recipehome/RecipeHome';
import AvocadoAnimation from '../../components/avocadoAnimation/AvocadoAnimation';



function Home() {

//   const [gptRes, setGptRes] = useState("")
  const [dataFromUserForm, setDataFromUserForm] = useState([])
  const [recipeAvailability, setRecipeAvailability] = useState(false)
  const [recipeName, setrecipeName] = useState("")
  const [ingredients, setIngredients] = useState([])
  const [timetoprepare, setTimetoprepare] = useState("")
  const [calories, setCalories] = useState("")
  const [protein, setProtein] = useState("")
  const [instructions, setInstructions] = useState([])
  const [generatingStat, setStat] = useState(false)

  const handleDataFromForm = (data) => {
    try{

       data = JSON.parse(data)
       setrecipeName(data.recipeName)
       setIngredients(data.ingredients)
       setTimetoprepare(data.timetoprepare)
       setCalories(data.calories)
       setProtein(data.protein)
       setInstructions(data.instructions)
       setRecipeAvailability(true)
    }catch(err){
        alert("Unable to Generate Recipe! Try Again")
        console.log(err)
    }

    // setDataFromUserForm(data)
  }

  const handleAnimation = (generatingstatus) => {
      setStat(generatingstatus)
  }

  return (
    <div className='home-container'>
        <div className="home-left">
            <div className="home-section-title">
                <AiOutlineForm className="icon" />
                Let us know your ingredients, preferences, restrictions, macros
            </div>
            {/* <div className={'home-section-form'}>
                <UserForm onData={handleDataFromForm} generatingstatus={handleAnimation}/>
            </div> */}
            <div className={generatingStat ? 'hidden' : 'home-section-form'}>
                <UserForm onData={handleDataFromForm} generatingstatus={handleAnimation}/>
            </div>

        </div>
        {
          recipeAvailability  && ! generatingStat ?  
          <div className="home-right">
              <RecipeHome recipeName= {recipeName} ingredients={ingredients} timetoprepare={timetoprepare} calories={calories} protein={protein} instructions={instructions}/> 
          </div> 
          : 
          <div className={!recipeAvailability && !generatingStat ? 'hide-on-phone': 'home-right-alternative'}>
                <AvocadoAnimation/>
          </div>
        }
       
        
    </div>
  )
}

export default Home